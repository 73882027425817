import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { country } from '@features/country/data';
import { toastError } from '@presentation/shared/toast';
import * as Merchant from '@taager-experience-shared/merchant';
import { CartModel } from 'src/app/core/domain/cart.model';
import { ProductAvailability, VariantModel } from 'src/app/core/domain/variant-group.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetVariantsByProductIdsUseCase } from 'src/app/core/usecases/products/get-variants-by-product-ids.usecase';
// Ignoring the linting check because this comes from a Kotlin Library
// @ts-ignore
import { getSupportedPhoneCountriesUseCase } from '@taager-experience-shared/country-resolver';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import {
  ProvinceModel,
  ProvinceZoneDistrictModel,
  ProvinceZoneModel,
} from 'src/app/core/domain/provinces.model';
import { GetProvinceListUseCase } from 'src/app/core/usecases/province/get-province-list.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { ADDRESS_AUTOCONFIRMATION_USER_FEATURE, PREPAID_ORDERS } from '../../shared/constants';
import { PREPAID_COLUMN_OPTIONS } from '../../shared/constants/cart';
import { QUANTITY_DISCOUNT_VALUE } from '../../shared/constants/quantity-discount';
import { InvalidHeadersError } from '../../shared/error-classes/invalid-headers.error';
import { Country } from '../../shared/interfaces/countries';
import { UtilityService } from '../../shared/services/utility.service';
import { BulkOrderVideoComponent } from '../bulk-order-video/bulk-order-video.component';
import { BulkOrdersErrorsComponent } from '../bulk-orders-errors/bulk-orders-errors.component';
import { UploadedOrderLine } from './cart-bulk-orders-interfaces';
import Province = Merchant.com.taager.experience.provinces.domain.entity.Province;
import GetAllProvincesUseCaseAsync = Merchant.com.taager.experience.provinces.domain.interactor.GetAllProvincesUseCaseAsync;

const ROW_START_OFFSET = 1;

@Component({
  selector: 'app-cart-bulk-orders-actions',
  templateUrl: './cart-bulk-orders-actions.component.html',
  styleUrls: ['./cart-bulk-orders-actions.component.scss'],
  standalone: true,
  imports: [NgIf, LoaderComponent, NgClass, AsyncPipe],
})
export class CartBulkOrdersActionsComponent extends LocalizedComponent implements OnInit {
  @Input() cartData: CartModel;

  @Input() uploadedVariantsHashMap: { [productId: string]: VariantModel };

  @Input() disableActions = true;

  @Output() bulkOrdersSheetUploadSuccessful = new EventEmitter<Array<UploadedOrderLine>>();

  public cartAssetsPath: string;

  public errorMessages: Array<{ row: string; error: string; label?: string }>;

  public isLoading = false;

  public shouldShowDetailedAddress = false;

  private provinces: Array<Province>;

  private _listOfProvinces: ProvinceModel[];

  private _listOfZones: ProvinceZoneModel[];

  private _listOfDistricts: ProvinceZoneDistrictModel[];

  private uploadedOrderLines: Array<UploadedOrderLine>;

  private _hasPrepaidFeature = false;

  constructor(
    private _dialog: MatDialog,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _utilityService: UtilityService,
    @Inject(GetAllProvincesUseCaseAsync)
    private _getAllProvinces: GetAllProvincesUseCaseAsync,
    private _getVariantsByProductIdsUseCase: GetVariantsByProductIdsUseCase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    private _getProvinceListUseCase: GetProvinceListUseCase,
  ) {
    super();
  }

  ngOnInit(): void {
    this.cartAssetsPath = '../../../assets/img/cart-icons/';
    this._shouldShowDetailedAddress();
    this._getProvinces();
    this._hasPrepaidFeature = this._checkUserFeatureExistsUseCase.execute(PREPAID_ORDERS);
  }

  public openVideoDialog(): void {
    this._dialog.open(BulkOrderVideoComponent, {
      width: '800px',
    });
    this._logMixpanelEventUseCase.execute({ eventName: 'bulk_upload_video_button_clicked' });
  }

  public downloadTemplate(): void {
    const featureFlag = {
      shouldShowDetailedAddress: this.shouldShowDetailedAddress,
    };
    this._utilityService.exportCartToExcel(
      this.cartData?.products?.filter((product) => product.isAvailableToSeller) || [],
      'bulk_orders',
      this.provinces,
      country.data!,
      featureFlag,
      this._listOfZones,
      this._listOfDistricts,
    );
    this._trackMixpanelCartEvent('bulk_upload_excel_sheet_download');
  }

  public onUploadBulkOrdersFile(event: any): void {
    this.uploadedOrderLines = [];
    this.errorMessages = [];

    const target: DataTransfer = event.target as DataTransfer;

    if (target.files.length !== 1) {
      toastError(this._getTranslatedText('CART.BULK_ORDERS.CANNOT_UPLOAD_MULTIPLE_FILES'));
    } else {
      this._utilityService
        .getUploadedOrdersFromExcelFile(target.files[0])
        .then((parsedOrderLines: Array<Array<string>>) => {
          this._getUploadedOrderLines(parsedOrderLines);
          this._validateUploadedOrderLines();
        })
        .catch((e) => {
          if (e instanceof InvalidHeadersError) {
            const error = this._getTranslatedText('CART.BULK_ORDERS.ERRORS.INVALID_TEMPLATE');
            const label = this._getTranslatedText('CART.BULK_ORDERS.ERRORS.MISSING_COLUMNS');
            toastError(`${error} (${label})`);
          } else {
            toastError(this._getTranslatedText('CART.BULK_ORDERS.PARSING_ERROR'));
          }
        });
    }

    // reset the input file again to allow the merchant reupload the same file
    event.target.value = '';
  }

  public onShowBulkOrderErrorsClicked(): void {
    this._openBulkOrderErrorsDialog();
    this._trackBulkOrderErrorsEvent('bulk_upload_show_errors_button_clicked');
  }

  private _shouldShowDetailedAddress(): void {
    this.shouldShowDetailedAddress = this._checkUserFeatureExistsUseCase.execute(
      ADDRESS_AUTOCONFIRMATION_USER_FEATURE,
    );
    if (this.shouldShowDetailedAddress) {
      this._getListOfAllProvinceZonesDistricts();
    }
  }

  private _getListOfAllProvinceZonesDistricts(): void {
    this._getProvinceListUseCase.execute(country.code).subscribe({
      next: (response) => {
        this._listOfProvinces = response;
        this._setupZonesAndDistricts();
      },
    });
  }

  private _setupZonesAndDistricts(): void {
    const zoneArray: ProvinceZoneModel[] = [];
    const districtArray: ProvinceZoneDistrictModel[] = [];

    this._listOfProvinces.forEach((province: ProvinceModel) => {
      province.zones?.forEach((zone: ProvinceZoneModel) => {
        zoneArray.push(zone);
        zone.districts?.forEach((district: ProvinceZoneDistrictModel) => {
          districtArray.push(district);
        });
      });
    });
    this._listOfZones = zoneArray;
    this._listOfDistricts = districtArray;
  }

  private _getProvinces(): void {
    this.isLoading = true;
    this._getAllProvinces
      .execute(country.code)
      .then((provinces) => {
        this.provinces = provinces;
      })
      .catch(() => {
        toastError(this.trans('CART.BULK_ORDERS.ERRORS.CANNOT_VERIFY_PROVINCES'));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private _trackMixpanelCartEvent(eventName: string): void {
    this._logMixpanelEventUseCase.execute({
      eventName,
      payload: {
        'Number of Products': this.cartData?.products.length || 0,
        'Total Products': this.cartData.totalQuantity,
        'Total Price': this.cartData.totalPrice,
        'Total Profit': this.cartData.totalProfit,
        'Total Net Profit': this.cartData.totalNetProfit,
        'Product Ids': this.cartData?.products.map((product) => product.prodId) || [],
        ...(this.errorMessages?.length
          ? { error: this.errorMessages.map((err) => `${err.error} (${err.label})`) }
          : {}),
      },
    });
  }

  private _getUploadedOrderLines(parsedOrderLines: Array<Array<string>>): void {
    const parseIndex = this.shouldShowDetailedAddress ? 2 : 0;
    // make sure to trim all values from any whitespace
    this.uploadedOrderLines = parsedOrderLines
      .map((parsedLine) => ({
        productId: parsedLine[0]?.trim(),
        productName: parsedLine[1]?.trim(),
        productNewPrice: parsedLine[2]?.trim(),
        productQty: parsedLine[3]?.trim(),
        receiverName: parsedLine[4]?.trim(),
        province: parsedLine[5]?.trim(),
        ...(this.shouldShowDetailedAddress ? { zone: parsedLine[6]?.trim() } : {}),
        ...(this.shouldShowDetailedAddress ? { district: parsedLine[7]?.trim() } : {}),
        streetName: parsedLine[6 + parseIndex]?.trim(),
        phoneNumber: parsedLine[7 + parseIndex]?.trim(),
        phoneNumber2: parsedLine[8 + parseIndex]?.trim() || '',
        notes: parsedLine[9 + parseIndex]?.trim(),
        orderSource: {
          pageName: parsedLine[10 + parseIndex],
          pageUrl: parsedLine[11 + parseIndex],
        },
        country: country.code,
        ...(this._hasPrepaidFeature ? { isPrePaid: parsedLine[15] } : {}),
      }))
      .filter(this._isNotEmptyObject);
  }

  private _validateUploadedOrderLines(): void {
    this._checkStaticErrors();
    this._getVariantsAndCheckProductsErrors()
      .catch(() => {
        this._setErrorForCantVerifyProducts();
      })
      .finally(() => {
        if (this.errorMessages?.length) {
          this._trackBulkOrderErrorsEvent('bulk_upload_excel_sheet_upload_error');
        } else {
          this._trackMixpanelCartEvent('bulk_upload_excel_sheet_upload_success');
          this._formOrdersFromUploadedOrderLines();
          this.bulkOrdersSheetUploadSuccessful.emit(this.uploadedOrderLines);
        }
      });
  }

  private _checkStaticErrors(): void {
    this._checkEmptyCells();
    this._checkProvinceErrors();
    this._checkPhoneNumberErrors();
    this._checkCustomerNameErrors();
    this._checkDuplicateOrderLines();
    this._validatePrepaidColumn();
  }

  private _validatePrepaidColumn(): void {
    if (!this._hasPrepaidFeature) {
      return;
    }
    this._invalidPrepaidColumnValue();
    this._phoneNumberDiscrepancyOnPrepaidColumn();
  }

  private _invalidPrepaidColumnValue(): void {
    const allowedPrepaidValues: Array<string> = [
      PREPAID_COLUMN_OPTIONS.cash,
      PREPAID_COLUMN_OPTIONS.prepaid,
    ];
    for (const [index, data] of this.uploadedOrderLines.entries()) {
      const prepaidValue: string = data.isPrePaid!;
      if (!allowedPrepaidValues.includes(prepaidValue)) {
        this.errorMessages.push({
          row: (index + ROW_START_OFFSET).toString(),
          error: this._getTranslatedText(
            'CART.BULK_ORDERS.ERRORS.INVALID_PREPAID_COLUMN_VALUES.ERROR',
            { value: prepaidValue },
          ),
          label: this._getTranslatedText(
            'CART.BULK_ORDERS.ERRORS.INVALID_PREPAID_COLUMN_VALUES.LABEL',
          ),
        });
      }
    }
  }

  private _phoneNumberDiscrepancyOnPrepaidColumn(): void {
    const phoneNumberMap: {
      [phoneNumber: string]: Array<{ rowIndex: number; prePaidValue: string }>;
    } = {};
    for (const [rowIndex, orderLine] of this.uploadedOrderLines.entries()) {
      const { phoneNumber } = orderLine;
      if (!phoneNumberMap[phoneNumber!]) {
        phoneNumberMap[phoneNumber!] = [];
      }
      phoneNumberMap[phoneNumber!].push({ rowIndex, prePaidValue: orderLine.isPrePaid! });
    }
    const errorRows: Array<{ rowIndex: number; phoneNumber: string }> = [];
    for (const phoneNumber in phoneNumberMap) {
      if (phoneNumber in phoneNumberMap) {
        const phoneNumberPrepaidValues = new Set(
          phoneNumberMap[phoneNumber].map((phoneNumberMapItem) => phoneNumberMapItem.prePaidValue),
        );
        if (phoneNumberPrepaidValues.size !== 1) {
          phoneNumberMap[phoneNumber].forEach((phoneNumberMapItem) => {
            errorRows.push({
              rowIndex: phoneNumberMapItem.rowIndex + ROW_START_OFFSET,
              phoneNumber,
            });
          });
        }
      }
    }
    errorRows.sort((a, b) => (a.rowIndex < b.rowIndex ? -1 : 1));
    errorRows.forEach((errorRow) => {
      this.errorMessages.push({
        row: errorRow.rowIndex.toString(),
        error: this._getTranslatedText(
          'CART.BULK_ORDERS.ERRORS.DIFFERENT_PREPAID_VALUES_FOR_SAME_PHONE_NUMBER',
          { phoneNum: errorRow.phoneNumber },
        ),
      });
    });
  }

  private _checkEmptyCells(): void {
    for (const [index, line] of Object.entries(this.uploadedOrderLines) as [string, any][]) {
      const headersToBeChecked: any = [
        'receiverName',
        'country',
        'streetName',
        'province',
        'phoneNumber',
        'productNewPrice',
        'productQty',
        'productId',
      ];

      if (this._hasPrepaidFeature) {
        headersToBeChecked.push('isPrePaid');
      }

      headersToBeChecked.forEach((headerName: string) => {
        if (!line[headerName]) {
          this.errorMessages.push({
            row: (Number(index) + ROW_START_OFFSET).toString(),
            error: this._getTranslatedText(
              `CART.BULK_ORDERS.HEADERS.${this._camelCaseToUpperSnakeCase(headerName)}`,
            ),
            label: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.REQUIRED'),
          });
        }
      });
    }
  }

  private _checkProvinceErrors(): void {
    this._checkProvincesNotFetched();

    for (const [index, line] of Object.entries(this.uploadedOrderLines)) {
      const { province } = line;

      this._checkProvinceExist(Number(index), province!);
    }
  }

  private _checkProvincesNotFetched(): void {
    if (!this.provinces?.length) {
      this.errorMessages.push({
        row: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.ALL_ROWS'),
        error: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.CANNOT_VERIFY_PROVINCES'),
      });
    }
  }

  private _checkProvinceExist(rowIndex: number, provinceLocation: string): void {
    const province = this.provinces?.find((p) => p.location === provinceLocation);

    if (provinceLocation && this.provinces?.length && !province) {
      this.errorMessages.push({
        row: (rowIndex + ROW_START_OFFSET).toString(),
        error: `${this._getTranslatedText(
          'CART.BULK_ORDERS.ERRORS.THIS_PROVINCE',
        )} ${provinceLocation}`,
        label: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.DOESNT_EXIST_IN_COUNTRY'),
      });
    }
  }

  private _checkPhoneNumberErrors(): void {
    for (const [index, line] of Object.entries(this.uploadedOrderLines)) {
      const { phoneNumber, phoneNumber2, province } = line;
      this._checkPhoneNumberValidity(Number(index) + 1, phoneNumber!, province!);
      this._checkPhoneNumberValidity(Number(index) + 1, phoneNumber2!, province!);
    }
  }

  private _checkPhoneNumberValidity(
    rowNumber: number,
    phoneNumber: string,
    province: string,
  ): void {
    const isUAESelected = country.is('UAE');

    const countryPhoneValidation = isUAESelected
      ? null
      : getSupportedPhoneCountriesUseCase(false).find(
          (ctry: Country) => ctry.isoCode3 === country.code,
        );

    const countryPhonePrefix = countryPhoneValidation?.phoneNumPrefix
      ? Number(countryPhoneValidation.phoneNumPrefix)
      : null;

    if (phoneNumber && countryPhoneValidation) {
      const phoneNumberRegex = countryPhonePrefix
        ? new RegExp(`^(${countryPhonePrefix})?${countryPhoneValidation.phoneRegex.slice(1)}`)
        : new RegExp(countryPhoneValidation.phoneRegex);

      const requiredFirstCharacter = isUAESelected
        ? null
        : countryPhoneValidation.phoneNumberHint.charAt(0);
      const requiredPhoneNumberLength = countryPhoneValidation.phoneNumberHint.length;

      if (!phoneNumberRegex.test(phoneNumber)) {
        if (!phoneNumber?.startsWith(requiredFirstCharacter)) {
          this._setFirstCharacterError(rowNumber, phoneNumber, requiredFirstCharacter);
        }
        if (phoneNumber?.length !== requiredPhoneNumberLength) {
          this._setPhoneNumberLengthError(rowNumber, phoneNumber, requiredPhoneNumberLength);
        }
      }
    }
  }

  private _setFirstCharacterError(
    rowNumber: number,
    phoneNumber: string,
    requiredFirstCharacter: string,
  ): void {
    this.errorMessages.push({
      row: (rowNumber + ROW_START_OFFSET).toString(),
      error: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.INCORRECT_PHONE_NUMBER', {
        phoneNumber,
      }),
      label: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.SHALL_START_WITH_X', {
        requiredFirstCharacter,
      }),
    });
  }

  private _setPhoneNumberLengthError(
    rowNumber: number,
    phoneNumber: string,
    requiredPhoneNumberLength: number,
  ): void {
    this.errorMessages.push({
      row: (rowNumber + ROW_START_OFFSET).toString(),
      error: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.INCORRECT_PHONE_NUMBER', {
        phoneNumber,
      }),
      label: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.LENGTH_SHOULD_BE_N', {
        requiredPhoneNumberLength: requiredPhoneNumberLength.toString(),
      }),
    });
  }

  private _checkCustomerNameErrors(): void {
    for (const [index, line] of Object.entries(this.uploadedOrderLines)) {
      const customerName = line.receiverName;

      if (customerName?.length! > 50) {
        this.errorMessages.push({
          row: (Number(index) + ROW_START_OFFSET).toString(),
          error: ` ${this._getTranslatedText(
            'CART.BULK_ORDERS.ERRORS.CUSTOMER_NAME',
          )} "${customerName}"`,
          label: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.SHOULD_NOT_EXCEED_50_CHARACTERS'),
        });
      }
    }
  }

  private _checkDuplicateOrderLines(): void {
    const ordersMap: any = {};
    for (const [index, orderLine] of Object.entries(this.uploadedOrderLines)) {
      if (!ordersMap.hasOwnProperty(orderLine.phoneNumber!)) {
        ordersMap[orderLine.phoneNumber!] = {};
      }
      if (!ordersMap[orderLine.phoneNumber!].hasOwnProperty(orderLine.productId)) {
        ordersMap[orderLine.phoneNumber!][orderLine.productId!] = true;
      } else {
        const { productId, phoneNumber } = orderLine;
        this.errorMessages.push({
          row: (Number(index) + ROW_START_OFFSET).toString(),
          error: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.DUPLICATE_ROW'),
          label: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.DUPLICATE_ROW_LABEL', {
            productId,
            phoneNumber,
          }),
        });
      }
    }
  }

  private _getVariantsAndCheckProductsErrors(): Promise<void> {
    const productIds = [...new Set(this.uploadedOrderLines.map((line) => line.productId))].filter(
      Boolean,
    ) as string[];

    return new Promise((resolve, reject) => {
      this._getVariantsByProductIdsUseCase.execute(productIds).subscribe({
        next: (variants) => {
          variants.forEach((variant) => {
            this.uploadedVariantsHashMap[variant.prodID] = variant;
          });
          this._checkProductsErrors();
          resolve();
        },
        error: () => {
          reject();
        },
      });
    });
  }

  private _checkProductsErrors(): void {
    for (const [index, line] of Object.entries(this.uploadedOrderLines)) {
      const { productId, productQty } = line;
      const productPrice = line.productNewPrice;

      this._checkIfProductIdExists(Number(index), productId!);
      this._checkIfProductExistsInCountry(Number(index), productId!, country.code);
      this._checkProductPrice(Number(index), productId!, productPrice!, productQty!);
      this._checkProductQuantityIsValid(Number(index), productQty!);
      this._checkProductAvailability(Number(index), productId!);
    }
  }

  private _checkIfProductIdExists(rowIndex: number, productId: string): void {
    if (productId && !this.uploadedVariantsHashMap[productId]) {
      this.errorMessages.push({
        row: (rowIndex + ROW_START_OFFSET).toString(),
        error: `${this._getTranslatedText('CART.BULK_ORDERS.ERRORS.PRODUCT_ID')} "${productId}"`,
        label: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.IS_NOT_CORRECT'),
      });
    }
  }

  private _checkIfProductExistsInCountry(
    rowIndex: number,
    productId: string,
    selectedCountryIsoCode: string,
  ) {
    if (
      this.uploadedVariantsHashMap[productId] &&
      this.uploadedVariantsHashMap[productId]?.country !== selectedCountryIsoCode
    ) {
      this.errorMessages.push({
        row: (rowIndex + ROW_START_OFFSET).toString(),
        error: `${this._getTranslatedText('CART.BULK_ORDERS.ERRORS.THIS_PRODUCT')} "${productId}"`,
        label: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.DOESNT_EXIST_IN_COUNTRY'),
      });
    }
  }

  private _checkProductPrice(
    rowIndex: number,
    productId: string,
    price: string,
    quantity: string,
  ): void {
    this._checkPriceIsNumber(rowIndex, price);
    this._checkProductPriceIsValid(rowIndex, productId, price, quantity);
  }

  private _checkPriceIsNumber(rowIndex: number, price: string): void {
    if (price && !parseInt(price, 10)) {
      this.errorMessages.push({
        row: (rowIndex + ROW_START_OFFSET).toString(),
        error: `${this._getTranslatedText('CART.BULK_ORDERS.ERRORS.PRODUCT_PRICE')} "${price}"`,
        label: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.IS_NOT_CORRECT'),
      });
    }
  }

  private _checkProductPriceIsValid(
    rowIndex: number,
    productId: string,
    price: string,
    quantity: string,
  ): void {
    const variant = this.uploadedVariantsHashMap[productId];
    const originalPrice = variant?.productPrice;
    const salePrice = variant?.sale?.productPrice;
    const finalPrice = salePrice || originalPrice;
    const quantityDiscount =
      variant.customAdditionalQuantityDiscount?.amount || QUANTITY_DISCOUNT_VALUE;
    const qtyDiscount = Math.round(
      (finalPrice + (finalPrice - quantityDiscount) * (+quantity - 1)) / +quantity,
    );
    const discountedPrice =
      variant?.discountedAsSecondInPlacement?.enabled &&
      variant?.discountedAsSecondInPlacement?.discountedPrice;

    const leastAllowedPrice =
      +quantity > 1 ? qtyDiscount : salePrice || discountedPrice || originalPrice;

    if (price && Number(price) < leastAllowedPrice) {
      this.errorMessages.push({
        row: (rowIndex + ROW_START_OFFSET).toString(),
        error: `${this._getTranslatedText(
          'CART.BULK_ORDERS.ERRORS.PRODUCT_PRICE',
        )} ${productId} - ${variant.productName}`,
        label: `${this._getTranslatedText(
          'CART.BULK_ORDERS.ERRORS.CANNOT_BE_LESS_THAN',
        )} ${leastAllowedPrice}`,
      });
    }
  }

  private _checkProductQuantityIsValid(rowIndex: number, quantity: string): void {
    if (quantity && !parseInt(quantity, 10)) {
      this.errorMessages.push({
        row: (rowIndex + ROW_START_OFFSET).toString(),
        error: `${this._getTranslatedText(
          'CART.BULK_ORDERS.ERRORS.PRODUCT_QUANTITY',
        )} "${quantity}"`,
        label: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.IS_NOT_CORRECT'),
      });
    }
  }

  private _checkProductAvailability(rowIndex: number, productId: string): void {
    const variant = this.uploadedVariantsHashMap[productId];

    if (
      variant?.productAvailability === ProductAvailability.not_available ||
      variant?.productAvailability === ProductAvailability.available_for_preorder
    ) {
      this.errorMessages.push({
        row: (rowIndex + ROW_START_OFFSET).toString(),
        error: `${this._getTranslatedText('CART.BULK_ORDERS.ERRORS.THIS_PRODUCT')} ${productId} - ${
          variant.productName
        }`,
        label: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.IS_NOT_AVAILABLE'),
      });
    }
  }

  private _setErrorForCantVerifyProducts(): void {
    this.errorMessages.push({
      row: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.ALL_ROWS'),
      error: this._getTranslatedText('CART.BULK_ORDERS.ERRORS.CANNOT_VERIFY_PRODUCTS'),
    });
  }

  private _openBulkOrderErrorsDialog(): void {
    this._dialog.open(BulkOrdersErrorsComponent, {
      width: '570px',
      height: '100vh',
      position: {
        right: '0px',
      },
      panelClass: 'bulk-errors-dialog',
      data: {
        errorMessages: this.errorMessages,
      },
    });
  }

  private _trackBulkOrderErrorsEvent(eventName: string): void {
    this._logMixpanelEventUseCase.execute({
      eventName,
      payload: {
        errors: this.errorMessages.map((err) => `${err.error} (${err.label})`),
        numberOfErrors: this.errorMessages.length,
      },
    });
  }

  private _formOrdersFromUploadedOrderLines(): void {
    this._adjustEgyPhoneNumbers();
    this._setOrderLinesIdsGroupingSimilarPhoneNumbers();
    this._sortOrderLinesByIds();
    this._addProductDetailsToOrderLines();
  }

  private _adjustEgyPhoneNumbers(): void {
    const isEgyptOrder = country.is('EGY');
    this.uploadedOrderLines = this.uploadedOrderLines.map((orderLine) => ({
      ...orderLine,
      phoneNumber:
        isEgyptOrder && orderLine.phoneNumber!.startsWith('1')
          ? `0${orderLine.phoneNumber}`
          : orderLine.phoneNumber,
    }));
  }

  private _setOrderLinesIdsGroupingSimilarPhoneNumbers(): void {
    const phoneNumbers = this.uploadedOrderLines.map((orderLine) => orderLine.phoneNumber);
    const nonDuplicatePhoneNumbers = [...new Set(phoneNumbers)];

    nonDuplicatePhoneNumbers.forEach((number, index) => {
      this.uploadedOrderLines
        .filter((orderLine) => orderLine.phoneNumber === number)
        .forEach((filteredOrderLine) => {
          filteredOrderLine.id = index + 1;
        });
    });
  }

  private _sortOrderLinesByIds(): void {
    this.uploadedOrderLines.sort((a, b) => a.id! - b.id!);
  }

  private _addProductDetailsToOrderLines(): void {
    this.uploadedOrderLines = this.uploadedOrderLines.map((orderLine) => {
      const variant = this.uploadedVariantsHashMap[orderLine.productId!];
      return {
        ...orderLine,
        categoryId: variant?.categoryId,
        productObjectId: variant?._id,
        productOriginalPrice:
          variant?.sale?.productPrice ||
          variant?.discountedAsSecondInPlacement?.discountedPrice ||
          variant.productPrice,
        productProfit: variant?.productProfit,
        customAdditionalQuantityDiscount: variant.customAdditionalQuantityDiscount,
        notes: orderLine.notes || '',
      };
    });
  }

  private _getTranslatedText(text: string, params = {}): string {
    return this.trans(text, params);
  }

  private _camelCaseToUpperSnakeCase = (camelCaseString: string) =>
    camelCaseString.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).toUpperCase();

  private _isNotEmptyObject = (line: Object) => {
    const excludedKeys = ['orderSource', 'country'];
    return Object.entries(line).some(([key, value]) => !excludedKeys.includes(key) && value);
  };
}
