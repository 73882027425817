/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, radix */
import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { toastError } from '@presentation/shared/toast';
import * as Merchant from '@taager-experience-shared/merchant';
import { CheckUserFeatureExistsUseCase } from 'app/core/usecases/user/check-user-feature-exists.usecase';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { lastValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MerchantOrderPreferencesModel, OrderLineModel } from 'src/app/core/domain/order-model';
import { VariantModel } from 'src/app/core/domain/variant-group.model';
import { LogGTagEventUseCase } from 'src/app/core/usecases/analytics/log-gtag-event.usecase';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CalculateOrderCostUseCase } from 'src/app/core/usecases/order/calculate-order-cost.usecase';
import { MakeOrderByCartUseCase } from 'src/app/core/usecases/order/make-order-by-cart';
import { GetUserCountryUseCase } from 'src/app/core/usecases/user-location/get-user-country.usecase';
import { UtilityService } from 'src/app/presentation/shared/services/utility.service';
import { OrderOverviewComponent } from '../../orders/order-overview/order-overview.component';
import { OrderPreferenceComponent } from '../../orders/order-preference/order-preference.component';
import { UpSellingFeedbackComponent } from '../../orders/up-selling-feedback/up-selling-feedback.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { ADDRESS_AUTOCONFIRMATION_USER_FEATURE, FEATURE_FLAGS } from '../../shared/constants';
import { PREPAID_COLUMN_OPTIONS } from '../../shared/constants/cart';
import { ORDER_RECEIVED_STATUS } from '../../shared/constants/order-statuses';
import { QUANTITY_DISCOUNT_VALUE } from '../../shared/constants/quantity-discount';
import { ResponsiveService } from '../../shared/services/responsive.service';
import { BulkUploadEventOrderObject, BulkUploadEventsPayload } from './interfaces';
import Province = Merchant.com.taager.experience.provinces.domain.entity.Province;
import GetAllProvincesUseCaseAsync = Merchant.com.taager.experience.provinces.domain.interactor.GetAllProvincesUseCaseAsync;

@Component({
  selector: 'app-bulk-orders',
  templateUrl: './bulk-orders.component.html',
  styleUrls: ['./bulk-orders.component.scss'],
  standalone: true,
  imports: [
    LoaderComponent,
    NgIf,
    NgFor,
    OrderOverviewComponent,
    UpSellingFeedbackComponent,
    OrderPreferenceComponent,
  ],
})
export class BulkOrdersComponent extends LocalizedComponent implements OnInit {
  @Input() orders: any;

  @Input() uploadedVariantsHashMap: { [productId: string]: VariantModel };

  @Output() submitted: EventEmitter<any> = new EventEmitter();

  @Output() back: EventEmitter<any> = new EventEmitter();

  loading = false;

  private _provinces: Province[];

  public ordersToSubmit: any[] = [];

  public failedOrders: any[] = [];

  public successfulOrders: any[] = [];

  public retryOrders: any;

  public failedOrdersFlag: boolean;

  public isSubmitted = false;

  public shouldShowUpOrderPreference = false;

  public shouldShowDetailedAddress = false;

  public orderPreference: MerchantOrderPreferencesModel;

  private _ordersObject: {
    [orderId: string]: {
      SKUs: {
        [productId: string]: {
          'Taager Selling Price': number;
          'Merchant Selling Price': number;
          'Merchant Profit': number;
          Quantity: number;
          categoryId: string;
        };
      };
    };
  } = {};

  constructor(
    private _makeOrderByCartUseCase: MakeOrderByCartUseCase,
    private utilityService: UtilityService,
    private _logGTagEventUseCase: LogGTagEventUseCase,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _getUserCountryUseCase: GetUserCountryUseCase,
    private _responsiveService: ResponsiveService,
    @Inject(GetAllProvincesUseCaseAsync)
    private readonly _getAllProvinces: GetAllProvincesUseCaseAsync,
    private _calculateOrderCostUseCase: CalculateOrderCostUseCase,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getProvinces().then(() => this.generateOrders());
    this.isOrderPreferenceEnabled();
    this._checkDetailedAddressStatus();
  }

  public isOrderPreferenceEnabled(): void {
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.ORDER_PREFERENCE).subscribe((flag) => {
      this.shouldShowUpOrderPreference = flag;
    });
  }

  private getProvinces(): Promise<void> {
    return this._getAllProvinces
      .execute(country.code)
      .then((provinces) => {
        this._provinces = provinces;
      })
      .catch(() => {
        toastError(this.trans('CART.BULK_ORDERS.ERRORS.CANNOT_VERIFY_PROVINCES'));
      });
  }

  generateOrders(): void {
    const count = this.orders.sort((x: any, y: any) => parseInt(y.id) - parseInt(x.id))[0].id;
    for (let index = 1; index <= count; index++) {
      const orderList = this.orders.filter((x: any) => x.id.toString() === index.toString());
      const orderObject = {
        id: index,
        products: [] as any[],
        productQuantities: [] as number[],
        productPrices: [] as number[],
        orderProfit: 0,
        productIds: [] as string[],
        cashOnDelivery: 0,
        receiverName: orderList[0].receiverName,
        streetName: orderList[0].streetName,
        phoneNumber: orderList[0].phoneNumber,
        phoneNumber2: orderList[0].phoneNumber2,
        province: orderList[0].province,
        ...(this.shouldShowDetailedAddress
          ? { zone: orderList[0].zone ? orderList[0].zone : undefined }
          : {}),
        ...(this.shouldShowDetailedAddress
          ? { district: orderList[0].district ? orderList[0].district : undefined }
          : {}),
        notes: orderList[0].notes,
        country: orderList[0].country,
        orderSource: {
          pageName: orderList[0].orderSource.pageName,
          pageUrl: orderList[0].orderSource.pageUrl,
        },
        status: 'draft',
        orderID: index,
        isPrePaid: orderList[0]?.isPrePaid === PREPAID_COLUMN_OPTIONS.prepaid,
        orderLines: [] as OrderLineModel[],
      };

      orderList.forEach((product: any) => {
        if (+product.productQty > 1) {
          product.productOriginalPrice = Math.round(
            (product.productOriginalPrice +
              (product.productOriginalPrice -
                (product.customAdditionalQuantityDiscount?.amount || QUANTITY_DISCOUNT_VALUE)) *
                (+product.productQty - 1)) /
              +product.productQty,
          );
        }
        orderObject.products.push(product.productObjectId);
        orderObject.productQuantities.push(+product.productQty);
        orderObject.productPrices.push(+product.productNewPrice * +product.productQty);
        orderObject.orderProfit +=
          (+product.productNewPrice - product.productOriginalPrice + product.productProfit) *
          +product.productQty;
        orderObject.productIds.push(product.productId);
        orderObject.orderLines.push({
          quantity: +product.productQty,
          totalPrice: +product.productNewPrice * +product.productQty,
          totalMerchantProfit:
            (+product.productNewPrice - product.productOriginalPrice + product.productProfit) *
            +product.productQty,
          productId: product.productId,
          productName: this.uploadedVariantsHashMap[product.productId]?.productName,
          productPicture: this.uploadedVariantsHashMap[product.productId]?.productPicture,
        });
      });
      const total: any = this.countTotal(orderList);
      let shipping = 0;
      const orderData = {
        province: orderList[0].province.toString(),
        products: orderObject.products,
        productQuantities: orderObject.productQuantities,
        productPrices: orderObject.productPrices,
        productIds: orderObject.productIds,
        orderLines: orderObject.orderLines,
      };
      this.loading = true;
      this._calculateOrderCostUseCase
        .execute(orderData)
        .pipe(
          finalize(() => {
            this.ordersToSubmit.push(orderObject);
            if (this.ordersToSubmit.length === count) {
              this.ordersToSubmit = this.ordersToSubmit.sort((a, b) => a.id - b.id);
              this.loading = false;
            }
          }),
        )
        .subscribe({
          next: (res) => {
            shipping =
              res?.shipping?.discountedRate ||
              this._getProvinceShippingRevenue(orderList[0].province.toString()) ||
              0;
            orderObject.cashOnDelivery = total.price + shipping;
          },
          error: () => {
            toastError(this.trans('CART.BULK_ORDERS.ERRORS.CALCULATION_ERROR'));
            shipping = this._getProvinceShippingRevenue(orderList[0].province.toString()) || 0;
            orderObject.cashOnDelivery = total.price + shipping;
          },
        });
    }
  }

  private _checkDetailedAddressStatus(): void {
    this.shouldShowDetailedAddress = this._checkUserFeatureExistsUseCase.execute(
      ADDRESS_AUTOCONFIRMATION_USER_FEATURE,
    );
  }

  private _getProvinceShippingRevenue(orderProvince: string): number {
    return this._provinces.find((province) => province.location.toString() === orderProvince)
      ?.shippingRevenue!;
  }

  public orderPreferencesChanged(orderPreferenceProperties: MerchantOrderPreferencesModel): void {
    this.orderPreference = orderPreferenceProperties;

    this.successfulOrders = this.successfulOrders.map((order) => {
      return {
        ...order,
        merchantOrderPreferences: orderPreferenceProperties,
      };
    });

    this.ordersToSubmit = this.ordersToSubmit.map((order) => {
      return {
        ...order,
        merchantOrderPreferences: orderPreferenceProperties,
      };
    });
  }

  public orderNow(): void {
    this.loading = true;

    this._logMixpanelEventUseCase.execute({
      eventName: 'bulk_upload_confirm_order_clicked',
    });
    const finalOrders: any[] = [];
    this.ordersToSubmit.forEach((val) =>
      finalOrders.push({ ...val, merchantOrderPreferences: this.orderPreference }),
    );

    this.orderAll(finalOrders, finalOrders.length - 1);

    this.isSubmitted = true;
  }

  orderAll(finalOrders: any, index: number): void {
    if (index < 0) {
      this.loading = false;
    } else {
      const element = finalOrders[index];
      delete element.id;
      delete element.orderID;
      delete element.status;
      this._makeOrderByCartUseCase
        .execute({ ...element, orderReceivedBy: 'bulk_upload' })
        .pipe(
          finalize(() => {
            if (index === 0) {
              this.failedOrders = this.ordersToSubmit
                .filter((order) => order.status !== ORDER_RECEIVED_STATUS)
                .map((order) => ({ ...order, status: 'error', orderID: 'error' }));
              this.successfulOrders = this.ordersToSubmit.filter(
                (order) => order.status === ORDER_RECEIVED_STATUS,
              );
              if (this.failedOrders.length) {
                this._logMixpanelEventUseCase.execute({
                  eventName: 'bulk_upload_confirm_order_error',
                  payload: {
                    errors: this.failedOrders.map((order) => order.errorMessage),
                    numberOfFailedOrders: this.failedOrders.length,
                    numberOfSuccessfulOrders: this.successfulOrders.length,
                  },
                });
              }
              if (this.successfulOrders.length) {
                this._ordersListFinishedBeingPlacedNowLogEvent();
              }
            }
            this.orderAll(finalOrders, index - 1);
            this.shouldShowUpOrderPreference = false;
          }),
        )
        .subscribe({
          next: (res) => {
            this._inflateOrderIdInOrdersObject(res.order.orderID, element);
            this.ordersToSubmit[index].orderID = res.order.orderID;
            this.ordersToSubmit[index].status = ORDER_RECEIVED_STATUS;
          },
          error: (err) => {
            this.ordersToSubmit[index].orderID = 'error';
            this.ordersToSubmit[index].status = 'error';
            this.ordersToSubmit[index].errorMessage = err?.error?.msg;

            this.failedOrdersFlag = true;
            if (err.status === 409 && err.error.msg === 'One or more products is not available') {
              toastError(this.trans('CART.BULK_ORDERS.ERRORS.PRODUCTS_UNAVAILABLE'));
            }
          },
        });
    }
  }

  private _inflateOrderIdInOrdersObject(orderId: string, element: any): void {
    const productsUnderThisOrder: Array<any> = this.orders.filter(
      (product: any) => element.productIds.indexOf(product.productId) > -1,
    );
    const SKUs: any = {};
    productsUnderThisOrder.forEach((orderedProduct) => {
      let { productOriginalPrice, productNewPrice, productQty, productProfit, categoryId } =
        orderedProduct;
      productOriginalPrice = parseInt(productOriginalPrice);
      productNewPrice = parseInt(productNewPrice);
      productQty = parseInt(productQty);
      productProfit = parseInt(productProfit);
      SKUs[orderedProduct.productId] = {
        'Taager Selling Price': productOriginalPrice * productQty,
        'Merchant Selling Price': productNewPrice * productQty,
        'Merchant Profit': (productProfit + (productNewPrice - productOriginalPrice)) * productQty,
        Quantity: productQty,
        categoryId,
      };
    });
    this._ordersObject[orderId] = { SKUs };
  }

  private async _ordersListFinishedBeingPlacedNowLogEvent(): Promise<void> {
    // Now we log to analytics
    this._logEventToAnalytics({
      'Taager ID': user.id,
      Platform: `Web-${this._responsiveService.returnDeviceCategory()}`,
      'Phone Number': user.phoneNumber,
      'User Location': await lastValueFrom(this._getUserCountryUseCase.execute()),
      'Order Objects': this._returnFormattedArrayOfOrderObjects(),
      'Orders Count': this.successfulOrders.length,
    });

    // Finally, we reset the orderobjects object
    this._ordersObject = {};
  }

  /**
   * Now that we have a hash table of order objects, we can safely loop through the hash table
   * in an inexpensive manner, since the Big-O notation is of O(1)
   *
   * The overall algorithmic complexity of the below nesting will be of 1^2 = 1, so no effor at all.
   */
  private _returnFormattedArrayOfOrderObjects(): Array<BulkUploadEventOrderObject> {
    const cumulativeOrderObjects: Array<BulkUploadEventOrderObject> = [];
    for (const orderID in this._ordersObject) {
      if (orderID in this._ordersObject) {
        for (const SKUID in this._ordersObject[orderID].SKUs) {
          if (SKUID in this._ordersObject[orderID].SKUs) {
            const productUnderThisOrderId = this._ordersObject[orderID].SKUs[SKUID];
            cumulativeOrderObjects.push({
              orderID,
              SKUID,
              'Taager Selling Price': productUnderThisOrderId['Taager Selling Price'],
              'Merchant Selling Price': productUnderThisOrderId['Merchant Selling Price'],
              'Merchant Profit': productUnderThisOrderId['Merchant Profit'],
              Quantity: productUnderThisOrderId.Quantity,
              categoryId: productUnderThisOrderId.categoryId,
            });
          }
        }
      }
    }
    return cumulativeOrderObjects;
  }

  private async _logEventToAnalytics(payload: BulkUploadEventsPayload): Promise<void> {
    const eventName = 'Checkout_page_upload_bulk_orders';
    this._logGTagEventUseCase.execute({ eventName, payload });
    this._logMixpanelEventUseCase.execute({ eventName, payload });
  }

  downloadRetryOrders(): void {
    const failedOrdersIds = this.failedOrders.map((x) => x.id);

    this.retryOrders = this.orders.filter((x: any) => failedOrdersIds.includes(x.id));

    const prods = this.retryOrders.map((x: any) => {
      const errorMessage = this.failedOrders.find(
        (failedOrder) => x.id === failedOrder.id,
      )?.errorMessage;
      return {
        كود_المنتج: x.productId,
        اسم_المنتج: x.productName,
        سعر_المنتج: x.productNewPrice,
        كمية: x.productQty,
        اسم_العميل: x.receiverName,
        المحافظة: x.province,
        العنوان: x.streetName,
        رقم_الهاتف: x.phoneNumber,
        رقم_الهاتف2: x.phoneNumber2,
        ملاحظات: x.notes,
        اسم_صفحة_الفيسبوك: x.orderSource.pageName,
        لينك_الصفحة: x.orderSource.pageUrl,
        سبب_الرفض: this.trans(errorMessage),
      };
    });

    if (!prods || !prods.length) {
      return;
    }
    this.utilityService.extractToExcel(prods, 'bulk_orders_retry.csv');
    this._logMixpanelEventUseCase.execute({
      eventName: 'bulk_upload_error_excel_sheet_downloaded',
    });
  }

  countTotal(products: any): void {
    const total = products.reduce(
      (acc: any, item: any) => {
        if (+item.productQty > 1) {
          item.productOriginalPrice = Math.round(
            (item.productOriginalPrice +
              (item.productOriginalPrice -
                (item.customAdditionalQuantityDiscount?.amount || QUANTITY_DISCOUNT_VALUE)) *
                (+item.productQty - 1)) /
              +item.productQty,
          );
        }
        acc.price += item.productNewPrice * item.productQty;
        acc.profit +=
          (item.productProfit + +item.productNewPrice - item.productOriginalPrice) *
          +item.productQty;
        acc.count += item.productQty;

        return acc;
      },
      {
        price: 0,
        profit: 0,
        count: 0,
      },
    );
    return total;
  }

  backToCart(): void {
    this.back.emit();
  }
}
