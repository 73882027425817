<div class="cart-order-summary">
  <p class="cart-order-summary__title body1--bold d-none d-md-block">
    {{ trans('CART.CART_SUMMARY.TITLE') }}
  </p>
  <div class="cart-order-summary__details">
    <div class="cart-order-summary__row d-none d-md-flex">
      <span class="cart-order-summary__header body2--medium">
        {{ trans('CART.CART_SUMMARY.PRODUCTS_COUNT') }}
      </span>
      <span class="cart-order-summary__value body2--bold">{{ cartData.totalQuantity }}</span>
    </div>
    <div class="cart-order-summary__row">
      <span class="cart-order-summary__header body2--medium">
        {{ trans('CART.CART_SUMMARY.PRODUCTS_PRICE') }}
      </span>
      <span class="cart-order-summary__value body2--bold">
        {{ cartData.totalPrice }}
        {{ currency | currencyTranslate | translate | currencyShortName }}</span
      >
    </div>
    <ng-container *ngIf="shouldShowVAT">
      <div class="cart-order-summary__row">
        <span class="cart-order-summary__header body2--medium">{{
          trans('CART.CART_SUMMARY.PRODUCTS_PROFIT')
        }}</span>
        <span class="cart-order-summary__value body2--bold"
          >{{ cartData.totalProfit }}
          {{ currency | currencyTranslate | translate | currencyShortName }}</span
        >
      </div>
      <div class="cart-order-summary__row">
        <span class="cart-order-summary__header body2--medium">
          {{ trans('CART.CART_SUMMARY.VAT') }}
        </span>
        <span class="cart-order-summary__value body2--bold">
          <bdi class="body2--bold">{{ cartData.totalVat * -1 }}</bdi>
          {{ currency | currencyTranslate | translate | currencyShortName }}</span
        >
      </div>
    </ng-container>
    <div class="cart-order-summary__row">
      <span class="cart-order-summary__header body2--medium">
        {{ trans('CART.CART_SUMMARY.NET_PROFIT') }}
      </span>
      <span class="cart-order-summary__value cart-order-summary__value--highlighted body2--bold">
        {{ cartData.totalNetProfit }}
        {{ currency | currencyTranslate | translate | currencyShortName }}</span
      >
    </div>
    <div class="cart-order-summary__row qty-discount-row" *ngIf="shouldShowQuantityDiscount">
      <img src="assets/img/price-tag.svg" alt="price-tag" />
      <span class="cart-order-summary__header body2--medium qty-discount">
        {{ trans('CART.CART_SUMMARY.QUANTITY_DISCOUNT') }}
      </span>
      <span class="cart-order-summary__value body2--bold color-success">
        {{ cartData.totalQuantityDiscount! > 0 ? cartData.totalQuantityDiscount : 0 }}
        {{ currency | currencyTranslate | translate | currencyShortName }}</span
      >
    </div>

    <div class="cart-order-summary__cod-row d-none d-md-flex">
      <span class="cart-order-summary__header body2--medium">
        {{ trans('CART.CART_SUMMARY.COD') }}
      </span>
      <span class="cart-order-summary__value body2--bold"
        >{{ cartData.totalPrice }}
        {{ currency | currencyTranslate | translate | currencyShortName }}</span
      >
    </div>
  </div>

  <button
    *ngIf="!isDuplicateAccount; else isDuplicate"
    data-test-id="go-to-checkout-page"
    (click)="submitOrderClicked()"
    [disabled]="!canSubmitOrder || isMarketClosed"
    class="btn mat-raised-button cart-order-summary__checkout-button body1--bold"
  >
    <span class="d-md-inline d-none">{{ trans('CART.CART_SUMMARY.PLACE_ORDER_LABEL') }}</span>
    <span class="d-md-none d-inline">{{ trans('CART.CART_SUMMARY.PLACE_ORDER_SHORT_LABEL') }}</span>
  </button>
  <p *ngIf="!canSubmitOrder" class="checkout-error-label caption2--regular">
    {{ trans('CART.CART_SUMMARY.CHECKOUT_UNAVAILABLE') }}
  </p>
</div>

<ng-template #isDuplicate>
  <div class="duplicate-account">
    <img src="assets/img/product-details-icons/lock.svg" alt="" />
    {{ trans('wallet.duplicateAccountError') }}
  </div>
</ng-template>
