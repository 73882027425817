<app-second-product-discount-banner
  [discountAsSecondProductApplied]="discountAsSecondProductApplied"
></app-second-product-discount-banner>
<app-quantity-discount-banner
  *ngIf="shouldShowQuantityDiscount && bannerEnabled"
  (updateBannerVisibility)="bannerDisplayCheck()"
></app-quantity-discount-banner>
<div
  class="products-table"
  [ngClass]="{ 'products-table--empty': !products || products.length === 0 }"
>
  <div class="products-table__action-bar">
    <button
      class="products-table__delete-all-button body1--bold"
      (click)="deleteAllProducts()"
      [disabled]="!products || products.length === 0"
      data-test-id="delete-all-in-cart"
    >
      {{ trans('CART.DELETE_ALL') }}
    </button>
  </div>

  <div class="container products-table__headers">
    <div class="row no-gutters align-items-center">
      <div class="header caption1--regular" [ngClass]="shouldShowVAT ? 'col-4' : 'col-5'">
        {{ trans('CART.TABLE_HEADERS.PRODUCT') }}
      </div>
      <div class="col-2 header caption1--regular">
        {{ trans('CART.TABLE_HEADERS.ORIGINAL_PRICE') }}
      </div>
      <div class="col-2 header caption1--regular">{{ trans('CART.TABLE_HEADERS.PRICE') }}</div>
      <div class="col-2 header caption1--regular">
        {{ trans('CART.TABLE_HEADERS.QUANTITY') }}
      </div>
      <div class="col-1 header caption1--regular">
        {{ trans('CART.TABLE_HEADERS.PROFIT') }}
      </div>
      <div class="col-1 header caption1--regular" *ngIf="shouldShowVAT">
        {{ trans('CART.TABLE_HEADERS.VAT') }}
      </div>
    </div>
  </div>
  <div class="table-content container">
    <div
      *ngFor="let product of products; let index = index"
      class="table-element row no-gutters"
      [ngClass]="{ 'table-element--disabled': !product.isAvailableToSeller }"
    >
      <div class="product-disabled" *ngIf="!product.isAvailableToSeller">
        <p class="body1--bold mb-1 product-disabled__message">
          {{ trans('CART.PRODUCT_UNAVAILABLE') }}
        </p>
        <button
          class="d-flex justify-content-center product-disabled__delete-button body1--bold"
          (click)="onDeleteProductClicked(product.id)"
        >
          {{ trans('CART.DELETE_FROM_CART') }}
        </button>
      </div>

      <div class="product-info col-12" [ngClass]="shouldShowVAT ? 'col-md-4' : 'col-md-5'">
        <button class="product-info__delete-button" (click)="onDeleteProductClicked(product.id)">
          <img
            loading="lazy"
            src="assets/img/close.png"
            class="product-info__delete-button-image"
          />
        </button>
        <a
          class="product-info__product-image-container"
          [routerLink]="[appURLs.PRODUCTS_V2_URL, product.id]"
          [queryParams]="{ name: product.name }"
        >
          <div
            class="lock-product-icon"
            *ngIf="shouldShowStockDistribution && product.availabilityInfo?.stockInfo"
          >
            <img src="assets/img/locked-products-lock.svg" />
          </div>
          <img loading="lazy" class="product-info__product-image" [src]="product.picture" />
        </a>
        <a
          class="product-info__product-details"
          [routerLink]="[appURLs.PRODUCTS_V2_URL, product.id]"
          [queryParams]="{ name: product.name }"
        >
          <p class="product-info__product-name body2--bold" [title]="product.name">
            {{ product.name }}
          </p>
          <app-cart-product-attributes [product]="product"></app-cart-product-attributes>
        </a>
      </div>

      <div class="col-md-2 col-12 original-price">
        <div class="original-price__container">
          <p class="mobile-header">{{ trans('CART.TABLE_HEADERS.ORIGINAL_PRICE') }}</p>
          <div class="original-price__amounts-container">
            <p
              class="original-price__striked-price mb-0 body1--bold"
              *ngIf="
                product.appliedDiscount?.applied ||
                (product.qty > 1 &&
                  shouldShowQuantityDiscount &&
                  product.originalPricePerItem === product.finalPricePerItem)
              "
            >
              {{ product.originalPricePerItem }}
            </p>
            <ng-container *ngIf="product.qty > 1 && shouldShowQuantityDiscount; else otherDiscount">
              <p class="original-price__normal-price--discounted body1--bold mb-0">
                {{ math.round(product.appliedDiscount.price) }}
              </p>
            </ng-container>
            <ng-template #otherDiscount>
              <p
                class="original-price__normal-price body1--bold mb-0"
                [ngClass]="{
                  'original-price__normal-price--discounted':
                    product.appliedDiscount?.applied ||
                    (product.qty > 1 &&
                      shouldShowQuantityDiscount &&
                      product.originalPricePerItem === product.finalPricePerItem)
                }"
              >
                {{
                  product.appliedDiscount?.applied
                    ? product.appliedDiscount?.price
                    : product.originalPricePerItem
                }}
              </p>
            </ng-template>
          </div>
        </div>
        <p
          class="original-price__second-product-discount caption2--bold mb-0"
          *ngIf="product.appliedDiscount?.type === 'SecondInPlacement'"
        >
          <i class="icon icon-discount-lightning"></i>
          {{ trans('CART.DISCOUNTED_AS_SECOND') }}
          {{ product.originalPricePerItem - product.appliedDiscount?.price }}
          {{ currency | currencyShortName }}
        </p>
      </div>

      <div class="col-md-2 col-12 desired-price">
        <p class="mobile-header">{{ trans('CART.TABLE_HEADERS.PRICE') }}</p>
        <div class="desired-price__input-wrapper">
          <input
            class="desired-price__input body1--bold"
            [ngClass]="{ 'desired-price__input--error': !product?.validity?.isValid }"
            (change)="onDesiredPriceChange()"
            type="number"
            [(ngModel)]="product.desiredPrice"
          />
          <p
            class="desired-price__error-message caption2--regular"
            *ngIf="!product?.validity?.isValid"
          >
            {{ trans('CART.INVALID_PRICE') }}
          </p>
        </div>
      </div>

      <div class="col-md-2 col-12">
        <div class="quantity">
          <p class="mobile-header">{{ trans('CART.TABLE_HEADERS.QUANTITY') }}</p>
          <button class="quantity__button body1--bold" (click)="onIncrementQuantity(product.id)">
            +
          </button>
          <input
            class="quantity__input body1--bold"
            (change)="onQuantityChange(product.id)"
            type="number"
            [(ngModel)]="product.qty"
            min="1"
          />
          <button class="quantity__button body1--bold" (click)="onDecrementQuantity(product.id)">
            -
          </button>
        </div>
        <p
          *ngIf="shouldShowStockDistribution && product.availabilityInfo?.stockInfo?.remainingStock"
          class="quantity-remaining"
        >
          {{ trans('CART.REMAINING') }}:
          <span> {{ product.availabilityInfo?.stockInfo?.remainingStock - product.qty }}</span>
        </p>
      </div>

      <div class="col-md-1 col-12 profit">
        <p class="mobile-header">{{ trans('CART.TABLE_HEADERS.PROFIT') }}</p>
        <div class="profit__amounts-container">
          <p
            class="profit__striked-profit body1--bold mb-0"
            *ngIf="
              product.appliedDiscount?.applied &&
              product.originalProfitPerItem * product.qty !==
                product.finalProfitPerItem * product.qty
            "
          >
            {{ product.originalProfitPerItem * product.qty }}
          </p>
          <p
            class="profit__normal-profit body1--bold mb-0"
            [ngClass]="{
              'profit__normal-profit--discounted': product.appliedDiscount?.applied
            }"
          >
            {{ product.finalProfitPerItem * product.qty }}
          </p>
        </div>
      </div>
      <div class="col-md-1 col-12 vat-amount" *ngIf="shouldShowVAT">
        <p class="mobile-header">{{ trans('CART.TABLE_HEADERS.VAT') }}</p>
        <p class="body1--bold mb-0 vat-amount__text">
          {{ product.vatPerItem * product.qty * -1 | number : '1.0-2' }}
        </p>
      </div>

      <div
        class="col-12"
        *ngIf="
          product.isAvailableToSeller &&
          shouldShowDiscountAsSecondProduct &&
          product.discountedAsSecondInPlacement?.enabled
        "
      >
        <div class="discounted-product-alert">
          <i class="icon icon-discount-lightning discounted-product-alert__icon"></i>
          <p class="caption1--bold discounted-product-alert__text">
            {{ trans('CART.DISCOUNTED_AS_SECOND_LABEL') }}
          </p>
        </div>
      </div>
    </div>
    <div class="table-element row" *ngIf="!products || products.length === 0">
      <div class="col-12 d-flex flex-column align-items-center">
        <p class="heading2--extra-bold">{{ trans('CART.EMPTY_CART') }}</p>
        <a
          [routerLink]="[appURLs.PRODUCTS_V2_URL]"
          class="shopping-button caption1--bold d-flex justify-content-center align-items-center"
          type="button"
        >
          <span class="body1--bold">{{ trans('CART.BROWSE_PRODUCTS') }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
